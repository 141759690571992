/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "./styles/thingwave-theme";

.transparent-snackbar {
  background: transparent !important;
  box-shadow: none !important;
  width: 100%;
  max-width: 500px !important;
  min-width: 400px !important;
  color: black;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }